@font-face {
  font-family: 'NutmegHeadline-UltraBlack';
  src: url('NutmegHeadline-UltraBlack.woff2') format('woff2'),
    url('NutmegHeadline-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('SFProText-Regular.woff2') format('woff2'), url('SFProText-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('Roboto-Medium.woff2') format('woff2'), url('Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('Roboto-Bold.woff2') format('woff2'), url('Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('Roboto-Regular.woff2') format('woff2'), url('Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
